/* eslint-disable camelcase */
import { PostHog } from 'posthog-js';
import { URLs } from '@manifoldxyz/js-ts-utils';
import { getClaimInstanceState, InstanceState } from '@/lib/util';

export type TelemetryProperties = {
  user_wallet?: string;
  user_mint_error?: string;
  instance_state?: InstanceState;
  app?: string;
  type?: string;
  'link-location'?: string;
  experiment?: string;
};

export interface IAnalytics {
  identify: (data: { distinctId: string; properties?: TelemetryProperties }) => void;
  capture: (data: { event: string; properties?: TelemetryProperties }) => void;
  reset: () => void;
}

export type TelemetryEvents =
  | 'claim_widget:transact_flow:start_claim_button_click'
  | 'claim_widget:transact_flow:checkout_start_button_click'
  | 'claim_widget:transact_flow:transaction_success'
  | 'claim_widget:transact_flow:transaction_error'
  | 'claim_widget:interaction_flow:buy_on_secondary_button_click'
  | 'claim_widget:create_your_own_flow:button_click'
  | 'claim_widget:interaction_flow:view_on_manifold_button_click';

/**
/**
 * Telemetry events that we want to track using posthog
 */
export const TELEMETRY_MAPPING: {
  mintFlow: {
    BUY_NOW_BUTTON_CLICK: TelemetryEvents;
    MINT_BUTTON_CLICK: TelemetryEvents;
    TOKEN_SUCCESS_MINT: TelemetryEvents;
    TOKEN_ERROR_MINT: TelemetryEvents;
    BUY_ON_SECONDARY_BUTTON_CLICK: TelemetryEvents;
    VIEW_ON_MANIFOLD_BUTTON_CLICK: TelemetryEvents;
    CREATE_YOUR_OWN_BUTTON_CLICK: TelemetryEvents;
  };
} = {
  mintFlow: {
    BUY_NOW_BUTTON_CLICK: 'claim_widget:transact_flow:start_claim_button_click',
    MINT_BUTTON_CLICK: 'claim_widget:transact_flow:checkout_start_button_click',
    TOKEN_SUCCESS_MINT: 'claim_widget:transact_flow:transaction_success',
    TOKEN_ERROR_MINT: 'claim_widget:transact_flow:transaction_error',
    BUY_ON_SECONDARY_BUTTON_CLICK: 'claim_widget:interaction_flow:buy_on_secondary_button_click',
    VIEW_ON_MANIFOLD_BUTTON_CLICK: 'claim_widget:interaction_flow:view_on_manifold_button_click',
    CREATE_YOUR_OWN_BUTTON_CLICK: 'claim_widget:create_your_own_flow:button_click',
  },
};

/**
 * Analytics implementation that just log data for local development
 */
export class LocalAnalytics implements IAnalytics {
  identify(data: { distinctId: string; properties?: TelemetryProperties }): void {
    console.log('identifying user', data);
  }

  capture(data: { event: string; properties?: TelemetryProperties }): void {
    console.log('capturing event', data);
  }

  reset(): void {
    console.log('resetting analytics');
  }
}

export class PosthogAnalytics implements IAnalytics {
  client: PostHog;

  constructor(client: PostHog) {
    this.client = client;
  }

  identify(data: { distinctId: string; properties?: TelemetryProperties }): void {
    this.client.identify(data.distinctId, data.properties);
  }

  capture(data: { event: string; properties?: TelemetryProperties }): void {
    this.client.capture(data.event, data.properties);
  }

  reset(): void {
    this.client.reset();
  }
}

export function posthogEnabled(): boolean {
  return (
    !!process.env.VUE_APP_POSTHOG_HOST &&
    !!process.env.VUE_APP_POSTHOG_KEY &&
    (process.env.NODE_ENV === 'production' || process.env.VUE_APP_ENABLE_POSTHOG === 'true')
  );
}

export function trackClick(name: string): void {
  try {
    window.plausible('Link Clicked', {
      props: {
        name,
      },
    });
  } catch (e) {}
}

export function subscribedToCreator(): void {
  try {
    window.plausible('Subscribed To Creator');
  } catch (e) {}
}

export function paymentMethodsPreview(): void {
  try {
    window.plausible('Payment Methods Preview');
  } catch (e) {}
}

export function paymentPreview(method: 'eth' | 'card'): void {
  try {
    window.plausible('Payment Preview', { props: { method: method } });
  } catch (e) {}
}

export function collectPaymentPreview(method: 'eth' | 'card'): void {
  try {
    window.plausible('Secondary - Payment Preview', { props: { method: method } });
  } catch (e) {}
}

export function paymentAttempt(
  method: 'eth' | 'card',
  tokensToPurchase: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet'
): void {
  try {
    window.plausible('Payment Attempt', { props: { method, tokensToPurchase, deliveryTo } });
  } catch (e) {}
}

export function collectPreview(method: 'eth' | 'card' | 'payment-method'): void {
  try {
    window.plausible('Secondary - Started', { props: { method } });
  } catch (e) {}
}

export function paymentSuccess(
  method: 'eth' | 'card',
  tokensMinted: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet',
  submethod: string
): void {
  try {
    window.plausible('Payment Success', {
      props: {
        method,
        tokensMinted,
        deliveryTo,
        submethod,
      },
    });
  } catch (e) {}
}

export function collectSuccess(
  method: 'eth' | 'card',
  tokensCollected: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet'
): void {
  try {
    window.plausible('Secondary - Success', {
      props: {
        method,
        tokensCollected,
        deliveryTo,
      },
    });
  } catch (e) {}
}

export function paymentError(
  method: 'eth' | 'card',
  tokensToPurchase: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet',
  error = 'unknown'
): void {
  try {
    window.plausible('Payment Error', { props: { method, tokensToPurchase, deliveryTo, error } });
  } catch (e) {}
}

export function collectError(
  method: 'eth' | 'card',
  tokensCollected: number,
  deliveryTo: 'custodial-wallet' | 'same-wallet' | 'different-wallet',
  error = 'unknown'
): void {
  try {
    window.plausible('Secondary - Error', {
      props: { method, tokensCollected, deliveryTo, error },
    });
  } catch (e) {}
}

const UTM_SOURCE = 'utm_source=collector_app';

function buildUTMString(utms: { medium: string; campaign: string }): string {
  return (
    '?' +
    UTM_SOURCE +
    '&' +
    Object.entries(utms)
      .map(([key, value]) => `utm_${key}=${value}`)
      .join('&')
  );
}

export function trackedGalleryContractLink(
  networkId: number,
  contractAddress: string,
  utms: {
    medium: string;
    campaign: string;
  }
): string {
  return URLs.getManifoldGalleryContractURL(networkId, contractAddress) + buildUTMString(utms);
}

export function trackedGalleryTokenLink(
  networkId: number,
  contractAddress: string,
  tokenId: string,
  utms: {
    medium: string;
    campaign: string;
  }
): string {
  return (
    URLs.getManifoldGalleryTokenURL(networkId, contractAddress, tokenId) + buildUTMString(utms)
  );
}

export function trackedMarketplaceLink(
  networkId: number,
  utms: { medium: string; campaign: string }
): string {
  return URLs.getManifoldMarketplaceBaseURL(networkId) + buildUTMString(utms);
}

interface TrackInstanceViewProps {
  status: 'not-started' | 'active' | 'ended';
  isSoldOut: boolean;
  analytics: IAnalytics;
  app: 'claim' | 'scribe' | 'pledge';
}

export function trackInstanceView({
  status,
  isSoldOut,
  analytics,
  app,
}: TrackInstanceViewProps): void {
  try {
    analytics.capture({
      event: '$pageview',
      properties: {
        instance_state: getClaimInstanceState({
          status,
          isSoldOut,
        }),
        app: app,
        type: 'instance',
      },
    });
  } catch (e) {}
}
