export enum InstanceState {
  /** start date is in the future */
  Upcoming = 'upcoming',
  /** start date is in the past, end date is in the future/never, and instance is not sold out */
  Live = 'live',
  /** start date is in the past, end date is in the future/never, but instance has no more supply */
  SoldOut = 'sold_out',
  /** end date is in the past */
  Ended = 'ended',
}

interface ClaimInstanceStateProps {
  status: 'not-started' | 'active' | 'ended';
  isSoldOut: boolean;
}

export function getClaimInstanceState({
  status,
  isSoldOut,
}: ClaimInstanceStateProps): InstanceState {
  if (status === 'active') {
    return InstanceState.Live;
  }
  if (status === 'not-started') {
    return InstanceState.Upcoming;
  }
  if (status === 'ended') {
    return InstanceState.Ended;
  }
  if (isSoldOut) {
    return InstanceState.SoldOut;
  }
  // reaching this point is an error
  throw new Error('Invalid claim instance state');
}
