// platform constants

import { Network, URLs } from '@manifoldxyz/js-ts-utils';
import {
  trackedGalleryContractLink,
  trackedGalleryTokenLink,
  trackedMarketplaceLink,
} from '@/lib/telemetry';

/** supported platforms for display */
export enum DisplayPlatform {
  Foundation = 'Foundation',
  Manifold = 'Manifold',
  Rarible = 'Rarible',
  LooksRare = 'LooksRare',
  OpenSea = 'OpenSea',
  MagicEden = 'MagicEden',
}

/** props for platform link display */
interface PlatformDisplayProps {
  /** supported chain ids */
  networks: Readonly<number[]>;
  /** icon to display */
  imageProps: { src: string; class?: string };
}

export const PLATFORM_DISPLAY_PROPS: { [platform in DisplayPlatform]: PlatformDisplayProps } = {
  [DisplayPlatform.Foundation]: {
    networks: [1],
    imageProps: { src: 'icon-foundation.svg' },
  },
  [DisplayPlatform.Manifold]: {
    networks: [1, 5, 10, 8453, 11155111],
    imageProps: { src: 'manifold-logograph.svg', class: 'manifold-logo' },
  },
  [DisplayPlatform.Rarible]: {
    networks: [1],
    imageProps: { src: 'icon-rarible.svg' },
  },
  [DisplayPlatform.LooksRare]: {
    networks: [1],
    imageProps: { src: 'icon-looksrare.svg' },
  },
  [DisplayPlatform.OpenSea]: {
    networks: [1, 5, 10, 137, 8453, 11155111],
    imageProps: { src: 'icon-opensea.svg' },
  },
  [DisplayPlatform.MagicEden]: {
    networks: [1, 137, 8453],
    imageProps: { src: 'icon-magic-eden.svg' },
  },
} as const;

/** order in which to display platform links */
export const PLATFORM_DISPLAY_ORDER: DisplayPlatform[] = [
  DisplayPlatform.OpenSea,
  DisplayPlatform.MagicEden,
  DisplayPlatform.LooksRare,
  DisplayPlatform.Rarible,
  DisplayPlatform.Foundation,
  DisplayPlatform.Manifold,
];

/** calculate the link based on the token details */
export function getPlatformLink(
  platform: DisplayPlatform,
  networkId: number,
  contractAddress: string,
  tokenId?: string | null,
  postCheckout = false
) {
  switch (platform) {
    case DisplayPlatform.OpenSea: {
      if (tokenId) {
        return URLs.getOpenSeaTokenURL(networkId, contractAddress, tokenId);
      } else {
        return URLs.getOpenSeaCollectionURL(networkId, contractAddress);
      }
    }
    case DisplayPlatform.MagicEden: {
      let networkName: string = Network.getNetworkNameForNetworkId(networkId);
      if (networkName === 'matic') {
        // @dev: Magic Eden uses the newer name for matic which is polygon
        networkName = 'polygon';
      }

      if (networkName === 'mainnet') {
        // @dev: Magic Eden calls mainnet, ethereum for some dumb reason
        networkName = 'ethereum';
      }

      if (tokenId) {
        return `https://magiceden.io/item-details/${networkName}/${contractAddress}/${tokenId}`;
      }
      return `https://magiceden.io/collections/${networkName}/${contractAddress}`;
    }
    case DisplayPlatform.LooksRare: {
      return `https://looksrare.org/collections/${contractAddress}${
        tokenId ? `/${tokenId}` : ''
      }?ref=9323239`;
    }
    case DisplayPlatform.Rarible: {
      if (tokenId) {
        return `https://rarible.com/token/${contractAddress}:${tokenId}`;
      }
      return `https://rarible.com/collection/${contractAddress}`;
    }
    case DisplayPlatform.Foundation: {
      return `https://foundation.app/collection/${contractAddress}`;
    }
    case DisplayPlatform.Manifold: {
      if (!contractAddress) {
        return trackedMarketplaceLink(networkId, {
          campaign: 'claim',
          medium: postCheckout ? 'post_checkout_links' : 'platform_links',
        });
      }
      if (!tokenId) {
        return trackedGalleryContractLink(networkId, contractAddress, {
          campaign: 'claim',
          medium: postCheckout ? 'post_checkout_links' : 'platform_links',
        });
      }
      return trackedGalleryTokenLink(networkId, contractAddress, tokenId, {
        campaign: 'claim',
        medium: postCheckout ? 'post_checkout_links' : 'platform_links',
      });
    }
  }
}
