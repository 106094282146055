// @ts-nocheck
import { createPinia } from 'pinia';
import { createApp, createVNode, render } from 'vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';
import { posthogEnabled } from '@/lib/telemetry';
export const renderComponentWithVNode = ({ el, component, props }) => {
  let vnode = createVNode(component, props);
  render(vnode, el);

  return () => {
    // destroy vnode
    render(null, el);
    vnode = undefined;
  };
};

/**
 * it renders the component into the given el with the given props
 * it provides the pinia store hooks
 * it provides the posthog hooks if enabled
 * it configures Sentry
 */
export const renderComponentWithApp = ({ el, component, props }) => {
  let app = createApp(component, props);

  if (posthogEnabled()) {
    posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
      api_host: process.env.VUE_APP_POSTHOG_HOST,
      // manually capturing instance view with instance state
      capture_pageview: false,
      capture_pageleave: true,
      person_profiles: 'identified_only',
    });
    posthog.register({
      app: 'claim',
    });
    app.provide('posthog', posthog);
  }

  const pinia = createPinia();

  app.use(pinia);

  Sentry.init({
    app: app,
    dsn: 'https://3760b5bc1fc147fbedf4acba26d1d97e@o1319289.ingest.sentry.io/4506582872686592',
    enabled: process.env.NODE_ENV === 'production',
    ignoreErrors: ['MetaMask: Received invalid isUnlocked parameter. Please report this bug.'],
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      new ExtraErrorData({
        depth: 6,
      }),
    ],
    normalizeDepth: 7,
    release: process.env.VITE_APP_GITHUB_SHA,
    sampleRate: 1.0,
  });

  VueQueryPlugin.install(app, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
    },
  });

  app.mount(el);

  return () => {
    // destroy app/component
    app?.unmount();
    app = undefined;
  };
};
